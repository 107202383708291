import React from "react"
import Accordian from "../../core/components/accordian"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ContactUs from "../../client/compositions/contact-us"
import Row from "../../core/components/row"
import Sticky from "../../core/components/sticky"
import Tagline from "../../client/compositions/tagline"
import Navigation from "../../core/components/navigation"
import contactDetails from "../../client/elements/contact-details"
import { quickMenu } from "../../client/elements/menus"

/**
 * About Page
 * @see https://zpl.io/a8gWDk4
 */
function AboutPage() {
  return (
    <article id="page-about" className="page-content">
      {quickMenu}

      <Banner
        title={
          <>
            Good wine
            <br />
            needs to be
            <br />
            seen
          </>
        }
      >
        <DecorationFluid image="about/banner-01.small.jpg" />
        <DecorationFluid image="about/banner-02.small.jpg" />
        <DecorationFluid image="about/banner-03.small.jpg" />
        <DecorationFluid image="about/banner-04.small.jpg" />
      </Banner>

      <Row id="about" className="long-text">
        <Block className="block-content">
          <Sticky margin={40} className="navigation-wrapper">
            <Navigation className="content-menu">
              <Anchor elementId="about-us">About Us</Anchor>
              <Anchor elementId="our-mission">Our Mission</Anchor>
              <Anchor elementId="the-founder">The Founder</Anchor>
              <Anchor elementId="contact-us">Contact Us</Anchor>
            </Navigation>

            {contactDetails}
          </Sticky>
        </Block>
        <Block className="block-content">
          <section id="about-us">
            <p>
              <em>
                InVinity Wine System LLC was founded by food and beverage
                concept developer and implementation specialist, Paul Koder. Mr
                Koder has more than 18 years of experience in the industry. Paul
                has worked with clientele all over the world, including the
                United States, Asia and the Middle East. He has consulted for
                some of the most renowned names in the global luxury hospitality
                and real estate sector, such as the St. Regis, W Hotels, Ritz
                Carlton, the Four Seasons and Rosewood Hotels.
              </em>
            </p>
            <p>
              As the founder and CEO of InVinity Wine System LLC, he has
              designed and installed deluxe controlled wine environments in some
              of the world’s most exclusive hotels, restaurants and residential
              complexes. After playing a key role in driving innovation in the
              wine storage industry, he has used his expertise in designing and
              creating the patented InVinity product line. This line is a
              groundbreaking system that offers a cost effective solution for
              luxury wall mounted wine racks and floor-to-ceiling wine racks the
              Inspire , Premier and Distinct lines.
            </p>
            <p className="decoration-wrapper">
              <DecorationFluid image="about/content-01.jpg" />
            </p>
          </section>
          <section id="our-mission">
            <h3 className="title">Show wine at its best</h3>
            <p>
              Paul’s connections with international chefs and sommeliers allow
              him to develop strategies that follow the latest trends in food &
              beverage storage solutions. With this in mind, he came up with a
              streamlined wine display system, consisting of diverse yet
              minimalistic metal wine racks with unsurpassed functionality and
              unparalleled aesthetic appeal. This received a design patent in
              2014.
            </p>
            <p className="blockquote-wrapper">
              <blockquote>
                The story begins with Invinity integrated into the space and
                showcasing the wine to excite those who will be at the table
                that evening.
              </blockquote>
            </p>
            <p className="decoration-wrapper">
              <DecorationFluid image="about/content-02.jpg" />
            </p>
          </section>
          <section id="the-founder">
            <h3 className="title">Paul Koder - Founder of Invinity</h3>
            <p className="narrow">
              “The art isn’t the wine display by itself, it is the story that
              begins with Invinity integrated into the space and showcasing the
              wine to excite those who will be at the table that evening.”
            </p>
            <p className="narrow">
              With more than two decades in the food and beverage concept
              development and implementation industry, Paul Koder is
              internationally regarded as one of the dominant authorities in
              designing storage solutions for the wine and food & beverage
              industry.
            </p>
            <p className="narrow">
              With luxury brands such as the St. Regis, W-Hotels, Ritz Carlton,
              Four Seasons and Rosewood Hotels as trusted clients, partners and
              friends, Paul’s connections with international chefs and
              sommeliers allow him to develop wine storage solutions that
              seamlessly integrate into the luxury hospitality process. Paul’s
              understanding of the importance in pairing function with aesthetic
              have led to the design and creation of award-winning wine displays
              celebrated for revolutionizing wine storage and restraint
              hospitality wine pairing.
            </p>
            <p className="narrow">
              Paul was awarded his culinary degrees in Paris, his sommelier
              certification in London, his agricultural and architecture degree
              in Beirut, his Invinity patents in the United States, and his eye
              for luxury hospitality from partnering with luxury brands and
              across the globe.
            </p>
            <p className="decoration-wrapper">
              <DecorationFluid image="about/content-03.medium.jpg" />
              <DecorationFluid image="about/content-04.medium.jpg" />
            </p>
          </section>
        </Block>
      </Row>

      <ContactUs id="contact-us">
        <DecorationFluid image="/about/contact.small.jpg" />
      </ContactUs>

      <Tagline />
    </article>
  )
}

export default AboutPage
